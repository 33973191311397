export const environment = {
  name: "staging",
  dnsRecordName: "px-shifamed-preprd.com",
  domain: "px-shifamed-preprd.com",
  apiUrl: "https://my.px-shifamed-preprd.com",
  oAuth: {
    clientId: "wDBuxmHXvgD3kwBp3hdq1tg62AOklBrm",
    domain: "auth.px-shifamed-preprd.com",
    audience: "proximie-web",
    connection: "",
  },
  api: {
    baseUrl: "https://api.px-shifamed-preprd.com",
  },
  assets: {
    flavorUrl: "https://live.px-shifamed-preprd.com/assets/config",
  },
  featureFlag: {
    url: "https://featureflags.px-shifamed-preprd.com/proxy",
    appName: "media-client",
    apiKey: "2CRUOHsxR6ESFQiBM4AU7Fts0IcUS0P",
    environment: "development",
  },
  permissions: "['support-ticket:create','speciality:read',]",
  supportLines: "{US:{link:'+1888-206-3779',label:'+1 888-206-3779'}, 'UK/EU':{link:'+448000584306',label:'+44 800 058 4306'}}",
};
